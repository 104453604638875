import React from "react";
import "./Summary.css";
function SummaryComponent({ year, title, camp, description }) {
  return (
    <div className="SummaryComponent h-96 lg:h-60 min-w-full max-h-full space-y-6">
      <div className="SummaryComponent_childWrapper p-3 text-white">
        <div className="SummaryComponent_title md:text-3xl text-xl font-bold text-white">
          {title}
        </div>
        <div className="SummaryComponent_title2 pb-2 pt-1 pl-2 text-gray-200 text-xl font-semibold opacity-90">
          {camp}
        </div>
        <button
          type="button"
          class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
        >
          {year}
        </button>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default SummaryComponent;
