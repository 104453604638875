import React, { useEffect, useState } from "react";
// import "./Home.css";
import { Link } from "react-scroll";
import { Link as Navlink } from "react-router-dom";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

function Nav() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div className="flex justify-between items-center max-w-full py-5 px-3">
      <Link
        activeClass="active"
        to="home"
        spy={true}
        smooth={true}
        offset={50}
        duration={700}
        delay={100}
        className="font-bold text-lg text-blue-500"
      >
        <div className="Home_header_name">Amanuel</div>
      </Link>
      <div className=" hidden lg:block">
        <div className="flex space-x-4 font-bold cursor-pointer lg:text-xl md:text-lg">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            Home
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            About
          </Link>
          <Link
            activeClass="active"
            to="service"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            What I Do
          </Link>
          <Link
            activeClass="active"
            to="resume"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            Resume
          </Link>
          <Link
            activeClass="active"
            to="portfolio"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            Portfolio
          </Link>
          <Link
            activeClass="active"
            to="client"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            Client
          </Link>
          <Link
            activeClass="active"
            to="contacts"
            spy={true}
            smooth={true}
            offset={50}
            duration={700}
            delay={100}
            className="text-white hover:text-blue-500"
          >
            Contact
          </Link>
        </div>
      </div>

      <div className="hidden lg:block ">
        <div className="flex space-x-4">
          <Navlink
            to="https://twitter.com/WondeAmanuel?t=2Wuj5rz3xUAxjkGVOUnX2A&s=08"
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="Nav_icon">
              <TwitterIcon />
            </div>
          </Navlink>
          <Navlink
            to="https://github.com/amanuelWonde"
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="Nav_icon">
              <GitHubIcon />
            </div>
          </Navlink>
          <Navlink
            to="https://www.linkedin.com/in/amanuel-wonde-358324277"
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="Nav_icon">
              <LinkedInIcon />
            </div>
          </Navlink>
        </div>
      </div>
      <div className="lg:hidden" onClick={toggleMenu}>
        <MenuIcon />
      </div>
      {showMenu && (
        <div
          className={`fixed z-50 bg-black justify-center inset-0 ${
            showMenu ? "opacity-100" : "opacity-0 pointer-events-none"
          } transition-all duration-500`}
        >
          <div className="md:hidden justify-items-end">
            <CloseIcon onClick={toggleMenu} />
          </div>
          <div className=" flex flex-col items-center text-white">
            <div className="flex flex-col space-y-4 cursor-pointer py-5 text-xl font-bold">
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                Home
              </Link>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                About
              </Link>
              <Link
                activeClass="active"
                to="service"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                What I Do
              </Link>
              <Link
                activeClass="active"
                to="resume"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                Resume
              </Link>
              <Link
                activeClass="active"
                to="portfolio"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                Portfolio
              </Link>
              <Link
                activeClass="active"
                to="client"
                spy={true}
                smooth={true}
                offset={50}
                duration={700}
                delay={100}
                className="text-white hover:text-blue-500"
                onClick={toggleMenu}
              >
                Client
              </Link>
            </div>
            <Link
              onClick={toggleMenu}
              className="w-1/2 text-center mt-10 m-auto bg-gradient-to-r from-purple-500 to-indigo-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gradient-to-r hover:from-purple-600 hover:to-indigo-600 transition-all duration-300"
            >
              Contact me
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Nav;
