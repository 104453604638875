import React from "react";
import PortfolioComponent from "./PortfolioComponent";
import amazon from "./Image/mariia-shalabaieva-V02us-4rNE8-unsplash.jpg";
import netflix from "./Image/alexandershatovUVfvFrp4x4EunsplashCopy.jpg";
import zoom from "./Image/rubaitul-azad-J0eqDgSQDYg-unsplash.jpg";
import merkastu from "./Image/Merkastulogo.png";
import adc from "./Image/adc-logo.png";
import evngadiForum from "./Image/meta-logo.jpg";
import ethIdol from "./Image/photo_2023-08-21_17-12-32.jpg";
import portal from "./Image/photo_2023-09-14_01-30-29.jpg";

const digital_merkato =
  "https://commercepal.com/static/media/footer-logo.4609ea7cdf1ae736d85f.png";

function Portfolio() {
  return (
    <div className="Portfolio bg-slate-400" id="portfolio">
      <div className="Portfolio_textWrapper">
        <div className=" text-center text-5xl md:text-8xl opacity-10 font-bold -mb-16 md:-mb-20 py-5">
          PORTFOLIO
        </div>
        <h2 className=" text-center text-xl md:text-4xl font-bold text-white">
          My Projects
        </h2>
      </div>
      <div className=" grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-8 px-10 ">
        <PortfolioComponent
          background={digital_merkato}
          prtitle="Commercepal"
          link="https://commercepal.com/browse"
        />
        <PortfolioComponent
          background={ethIdol}
          prtitle="Ethiopian Idol"
          link="https://amanuelwonde.github.io/Ethiopian_Idol/"
        />
        <PortfolioComponent
          background={portal}
          prtitle="MSS Apprenticeship Portal"
          link="https://learn.bluemarkcollege.com"
        />
        <PortfolioComponent
          background={merkastu}
          prtitle="Merkastu Ecommerce Website"
          link="https://merkastu.com"
        />
        <PortfolioComponent
          background={adc}
          prtitle="AASTU Developer's Community"
          link="https://adc.merkastu.com"
        />
        <PortfolioComponent
          background={evngadiForum}
          prtitle="Evangadi forum"
          link="https://forum-frontend-ajo.pages.dev/"
        />
      </div>

      <h2 className=" text-center text-xl md:text-4xl font-bold text-white py-6">
        Clones
      </h2>
      <div className=" grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-8 px-10 ">
        <PortfolioComponent
          background={zoom}
          prtitle="Amazon Clone"
          link="https://clone-f69e0.web.app"
        />
        <PortfolioComponent
          background={netflix}
          prtitle="Apple Clone"
          link="https://netflix-clone-25307.web.app"
        />

        <PortfolioComponent background={amazon} prtitle="Zoom Clone" />
      </div>
    </div>
  );
}

export default Portfolio;
