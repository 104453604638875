import React from "react";
import "./Service.css";
import PieChartIcon from "@material-ui/icons/PieChart";
import ServiceComponent from "./ServiceComponent";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import BrushIcon from "@material-ui/icons/Brush";
import HighlightIcon from "@material-ui/icons/Highlight";
function Service() {
  return (
    <div className="service" id="service">
      <div className="service_header">
        <div className=" text-center text-5xl md:text-8xl opacity-10 font-bold -mb-16 md:-mb-20 py-5">
          SERVICES
        </div>
        <h2 className=" text-center text-xl md:text-4xl font-bold text-white">
          What I Do?
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-5 md:px-20 py-10 w-full">
        <ServiceComponent
          icon={
            <PieChartIcon
              style={{ fontSize: 60, color: "rgba(35, 176, 148, 0.493)" }}
            />
          }
          header="Web Development"
        />

        <ServiceComponent
          icon={
            <BorderColorIcon
              style={{ fontSize: 60, color: "rgba(35, 176, 148, 0.493)" }}
            />
          }
          header="UX/UX Design"
        />
        <ServiceComponent
          icon={
            <DesktopWindowsIcon
              style={{ fontSize: 60, color: "rgba(35, 176, 148, 0.493)" }}
            />
          }
          header="Web Design"
        />

        <ServiceComponent
          icon={
            <BrushIcon
              style={{ fontSize: 60, color: "rgba(35, 176, 148, 0.493)" }}
            />
          }
          header="App  Design  & Develop"
        />
      </div>
    </div>
  );
}
export default Service;
