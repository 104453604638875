import React from "react";
import "./About.css";
import { Link } from "react-router-dom";
import SkillPercentage from "./SkillPersentage";
import ExpandableParagraph from "./ExpandableParagraph";
function About() {
  return (
    <div className="about" id="about">
      <div className="about_header">
        <div className=" text-center text-5xl md:text-8xl opacity-10 font-bold -mb-16 md:-mb-20 py-5">
          ABOUT ME
        </div>
        <h2 className=" text-center text-xl md:text-4xl font-bold text-white">
          Know Me More
        </h2>
      </div>
      <div className="about_information w-full grid grid-cols-1  md:grid-cols-2 gap-8 px-10 md:px-5">
        <div className="about_information_paragraph md:pt-28">
          <h3 className=" text-lg text-gray-100 font-medium">
            {" "}
            Welcome to my website! My name is{" "}
            <span className=" text-blue-700">Amanuel Wonde</span>, and I'm a
            junior full-stack web developer with a passion for building
            beautiful, intuitive, and user-friendly websites
          </h3>
          <br />
          <div className=" font-bold text-lg text-gray-100">
            <ExpandableParagraph
              text={
                "Ever since I was young, I've been drawn to computers and technology. As I grew older, I became more interested in web development and the ability to create websites that can reach people all over the world. I pursued my passion by studying Fullstack web development at Evangadi Tech and Software Engineering at AASTU, and since then, I've been building websites that help businesses and individuals achieve their goals online.\n\nAs a full-stack developer, I have experience working with a variety of programming languages, such as Javascript, Python, C++, JAVA, and frameworks such as React and Next js. I specialize in creating responsive websites that look great on any device, from desktop computers to mobile phones.\n\nMy approach to web development is to put the user's needs first. I believe that a website should be easy to use, intuitive, and visually appealing. I strive to create websites that not only look great, but also provide a seamless user experience.\n\nThank you for visiting my website, and I hope you enjoy exploring my portfolio of projects. If you have any questions or would like to collaborate on a project, please don't hesitate to get in touch via my contact page."
              }
            />
          </div>
        </div>

        <div className="about_information_email text-slate-300">
          {/* <ul>
            <li>Name: Amanuel Wonde</li>
            <hr />
            <li>
              Email: <Link to="">amanuelwt@gmail.com</Link>
            </li>
            <hr />
            <li>Age: 20</li>
            <hr />
            <li>From: Ethiopia, Addis Ababa</li>
            <hr />
            <button className="bg-blue-500 ml-6 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <a href="resume.pdf" download>
                Download Resume
              </a>
            </button>
          </ul> */}
          <img
            src="https://www.mohammedibrahim.tech/assets/coding-man-3f0328ea.webp"
            alt=""
          />
        </div>
      </div>

      <div className="p-10 space-y-1 lg:px-20">
        <h3 className="text-3xl font-bold text-white mb-6 text-center">
          Skills
        </h3>
        <SkillPercentage skillName="HTML" skillLevel="90" />
        <SkillPercentage skillName="CSS" skillLevel="85" />
        <SkillPercentage skillName="Tailwind CSS" skillLevel="90" />
        <SkillPercentage skillName="Javascript" skillLevel="85" />
        <SkillPercentage skillName="React" skillLevel="85" />
        <SkillPercentage skillName="Node.JS" skillLevel="80" />
        <SkillPercentage skillName="Express.JS" skillLevel="80" />
        <SkillPercentage skillName="MySQL" skillLevel="75" />
        <SkillPercentage skillName="MongoDB" skillLevel="70" />
        <SkillPercentage skillName="Python" skillLevel="90" />
        <SkillPercentage skillName="Django" skillLevel="70" />
        <SkillPercentage skillName="C++" skillLevel="75" />
        <SkillPercentage skillName="JAVA" skillLevel="60" />
      </div>
    </div>
  );
}

export default About;
