import React from "react";
import "./SkillPercentage.css";

function SkillPercentage(props) {
  const { skillName, skillLevel } = props;
  const percentage = skillLevel + "%";

  return (
    <div className="skill-percentage">
      <div className="skill-name py-3 text-slate-200">{skillName}</div>
      <div className="percentage-bar">
        <div className="percentage-fill" style={{ width: percentage }}>
          <span className="percentage-text">{percentage}</span>
        </div>
      </div>
    </div>
  );
}

export default SkillPercentage;
