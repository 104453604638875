import React from "react";
import SummaryComponent from "./SummaryComponent";
function Summary() {
  return (
    <div className="Summary w-full" id="resume">
      <div className="">
        <div className=" text-center text-5xl md:text-8xl opacity-10 font-bold -mb-16 md:-mb-20 py-5">
          SUMMARY
        </div>
        <h2 className=" text-center text-xl md:text-4xl font-bold text-white">
          Resume
        </h2>
      </div>
      <div className=" px-10 w-full grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-5 py-10">
        <div className="">
          <h2 className=" text-2xl font-bold text-slate-200 pl-10">
            My Education
          </h2>
          <SummaryComponent
            year="2018-2019"
            title="Fullstack Web Development"
            camp="Evangadi Network"
            description="Full Stack Web Development Bootcamp student at Evangadi Tech, gaining comprehensive knowledge in frontend and backend technologies. Mastering industry tools and techniques for building dynamic and responsive applications."
          />
          <SummaryComponent
            year="2022-Present"
            title="Software Engineer"
            camp="Addis Ababa Science and Technology University"
            description="Software Engineering student at AASTU, gaining a strong foundation in programming languages, software design principles, and algorithms. Equipped with the skills to tackle complex software engineering challenges and contribute to innovative technological solutions."
          />
        </div>

        <div className="">
          <h2 className=" text-2xl font-bold text-slate-200 pl-10">
            My Experience
          </h2>
          <SummaryComponent
            year="2020-Present"
            title="Founder/CEO"
            camp="Merkastu"
            description="Founder and CEO at Merkastu, a delivery website catering to the needs of university students. As a full-stack web developer, I lead the development of the platform, ensuring a seamless user experience and efficient delivery services for our student community."
          />
          <SummaryComponent
            year="2020-2021"
            title="Front-end Developer"
            camp="Evangadi Tech"
            description="Front End Developer at Evangadi Tech, creating engaging and user-friendly web experiences with a focus on cutting-edge design and seamless functionality. "
          />
          <SummaryComponent
            year="2022-Present"
            title="Fullstack Developer"
            camp="MSS Technology"
            description="Experienced Full Stack Web Developer & Project Manager at MSS Technology since 2022. Leading web development projects and ensuring successful delivery through technical expertise and effective project management."
          />
        </div>
      </div>
    </div>
  );
}

export default Summary;
