import React from "react";
import "./Contacts.css";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import TwitterIcon from "@material-ui/icons/Twitter";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Link } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));
function Contacts() {
  const classes = useStyles();

  return (
    <div className="Contacts" id="contacts">
      <div className="Contacts_header"></div>
      <div className=" text-center text-5xl md:text-8xl opacity-10 font-bold -mb-16 md:-mb-20 py-5">
        CONTACTS
      </div>
      <h2 className=" text-center text-xl md:text-4xl font-bold text-white">
        Get In Touch
      </h2>

      <div className="">
        <div className="">
          <form
            action=""
            className=" items-center md:w-1/2 m-auto w-full px-10"
          >
            <h3 className=" text-2xl text-slate-400 font-bold text-center py-5 opacity-80">
              SEND US A NOTE
            </h3>
            <div className=" text-white grid grid-cols-1 md:grid-cols-2 gap-5 md:w-10/12 w-full">
              <input
                type="text"
                placeholder="Name"
                size="32"
                name="email"
                className="pl-2 pr-10 py-3 md:my-5 bg-black rounded w-1/2 md:w-full"
              />
              <input
                type="text"
                placeholder="Email"
                size="32"
                className="pl-2 pr-10 py-3 bg-black mb-4 md:my-5 rounded w-1/2 md:w-full"
              />
            </div>

            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="Tell us more about your need..."
              className=" rounded pr-20 pl-4 md:pr-28 md:pl-8 md:ml-2  bg-black text-white mb-3 h-20 max-w-full"
            />
            <button type="submit">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Send
              </Button>
            </button>
          </form>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-2 gap-8 w-full px-10 md:px-16 lg:px-10 py-3 bg-gray-900">
          <div className=" space-y-2">
            <div>
              <LocationOnIcon style={{ color: "rgb(31, 204, 193)" }} />
              Addis Ababa,
              <span>Ethiopia</span>
            </div>

            <div className="Contacts_adress_adress_Email">
              <EmailIcon style={{ color: "rgb(31, 204, 193)" }} />
              <p>amanuelwt@gmail.com</p>
            </div>
          </div>

          <div className=" space-y-2">
            <div>
              <PhoneIcon style={{ color: "rgb(31, 204, 193)" }} /> +251
              967292497
            </div>
            <div className=" space-x-5  hover:text-blue-600 translate-x-2 text-cyan-700">
              <Link style={{ textDecoration: "none" }} className=" ">
                <FacebookIcon />
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="https://twitter.com/WondeAmanuel?t=2Wuj5rz3xUAxjkGVOUnX2A&s=08"
              >
                <TwitterIcon />
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="https://www.linkedin.com/in/amanuel-wonde-358324277"
              >
                <LinkedInIcon />
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to="https://github.com/amanuelWonde"
              >
                <GitHubIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
