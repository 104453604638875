import React, { useState } from "react";
import "./Portfolio.css";
import { Link } from "react-router-dom";
function PortfolioComponent({ background, prtitle, link }) {
  const [title, setTitle] = useState("");
  const hoverHendler = () => {
    setTitle(prtitle);
  };
  const downHandler = () => {
    setTitle("");
  };
  return (
    <div className=" h-60 my-5 px-5">
      <Link to={link}>
        <div
          className="PortfolioComponent h-60 w-full"
          onMouseOver={hoverHendler}
          onMouseOut={downHandler}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="blackBg bg-slate-950 h-full hover:bg-opacity-70 bg-opacity-0">
            <p className=" text-center font-bold  text-2xl text-slate-200 py-20">
              {title}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default PortfolioComponent;
