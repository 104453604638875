import React from "react";
import "./Home.css";
import { Link } from "react-scroll";
import Nav from "./Nav";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

function Home() {
  return (
    <div className="Home" id="home">
      <div className=" w-full h-full bg-black bg-opacity-60">
        <Nav />
        <div className=" my-36 text-center space-y-3">
          <h2 className=" text-2xl md:text-4xl font-bold text-slate-100">
            Welcome &nbsp;
            <span className=" text-xl md:text-3xl font-bold text-lime-500 ">
              I'm Amanuel Wonde
            </span>
          </h2>
          <h3 className=" text-2xl md:text-4xl font-bold text-blue-700">
            FullStack Web Developer
          </h3>
          <div className=" font-bold py-5">
            <Link
              activeClass="active"
              to="contacts"
              // spy={true}
              smooth={true}
              offset={50}
              duration={700}
              delay={100}
            >
              <Button variant="contained" color="primary">
                Hire me
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
