import React from "react";
import "./Service.css";
function ServiceComponent({ icon, header }) {
  return (
    <div className="ServiceComponent flex">
      <div className="ServiceComponent_icon">{icon}</div>
      <div className="ServiceComponent_paragraph">
        <div className=" text-slate-200 font-bold md:text-2xl text-lg py-8">
          {header}
        </div>
      </div>
    </div>
  );
}
export default ServiceComponent;
