import React from "react";
import "./Footer.css";
function Footer() {
  return (
    <div className="w-full text-center bg-slate-950 text-slate-300 py-1">
      <p>
        <span className=" text-2xl font-bold"> © </span>
        <span className=" text-blue-500">Amanuel.</span>

        <span>All Rights Reserved</span>
      </p>
    </div>
  );
}

export default Footer;
