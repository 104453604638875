import React, { useState } from "react";

function ExpandableParagraph(props) {
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  return (
    <div className="mb-4 font-bold">
      <p
        className={
          "text-lg font-medium leading-relaxed text-gray-100" +
          (expanded ? "" : " clamp-2")
        }
        style={{
          WebkitLineClamp: expanded ? "unset" : 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          display: "-webkit-box",
        }}
      >
        {props.text.split("\n").map((text, index) => (
          <React.Fragment key={index}>
            {text}
            <br />
          </React.Fragment>
        ))}
      </p>
      {expanded && (
        <button
          className="text-purple-700 font-medium hover:underline"
          onClick={toggleExpanded}
        >
          Read less
        </button>
      )}
      {!expanded && (
        <button
          className="text-purple-700 font-medium hover:underline"
          onClick={toggleExpanded}
        >
          Read more...
        </button>
      )}
    </div>
  );
}

export default ExpandableParagraph;
